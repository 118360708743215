<script setup lang="ts">
import type { City, Order, Reply, StatusElement, User } from '@/types/api'

const authStore = useAuthStore()

const store = useAppStore()
const globalStore = useGlobal()
const route = useRoute()
const router = useRouter()
let user = {} as User
const nuxtApp = useNuxtApp()
const { $socketOrders } = useNuxtApp()
const soundurl = ref('http://soundbible.com/mp3/analog-watch-alarm_daniel-simion.mp3')

async function getAuthenticated() {
  const result = await authStore.isAuthenticated()
  user = authStore.user
  if (!result) {
    router.push('/login')
  }
}

function setActiveDropdown() {
  const selector = document.querySelector(`ul.horizontal-menu a[href="${window.location.pathname}"]`)
  if (selector) {
    selector.classList.add('active')
    const all = document.querySelectorAll('ul.horizontal-menu .nav-link.active')
    for (let i = 0; i < all.length; i++) {
      all[0]?.classList.remove('active')
    }
    const ul = selector.closest('ul.sub-menu')! as HTMLUListElement

    if (ul) {
      const ele = ul.closest('li.menu')!.querySelectorAll('.nav-link')

      if (ele.length > 0) {
        const firstElement = ele[0]
        setTimeout(() => {
          firstElement.classList.add('active')
        })
      }
    }
  }
}

watch(route, () => {
  setActiveDropdown()
})

onMounted(() => {
  getAuthenticated()
  loadCities()
  loadStatus()
})

async function loadCities() {
  try {
    const response = await nuxtApp.$urlEfici.get<Reply<{ cities: City[] }>>(nuxtApp.$endpoint.cities.getAll.url)
    if (response.data.error)
      throw new Error('Error load roles')
    const cities = response.data.data.cities
    globalStore.CityState(cities)
  }
  catch (error) {
    console.error(error)
    return error
  }
}

async function loadStatus() {
  try {
    const response = await nuxtApp.$urlEfici.get<Reply<{ statuses: StatusElement[] }>>(nuxtApp.$endpoint.status.getAll.url)
    if (response.data.error)
      throw new Error(response.data.message)
    const statuses = response.data.data.statuses
    globalStore.StatusesState(statuses)

    const response2 = await nuxtApp.$urlRunner.get<Reply<StatusElement[]>>(nuxtApp.$endpoint.statusRunner.getAll.url)
    if (response2.data.error)
      throw new Error(response2.data.message)
    const statusesRunner = response2.data.data
    globalStore.StatusesRunnerState(statusesRunner)
  }
  catch (error) {
    console.error(error)
    return error
  }
}
</script>

<template>
  <header
    class="z-40"
  >
    <div class="shadow-sm">
      <div class="relative flex w-full items-center bg-white px-5 py-2.5 dark:bg-[#0e1726]">
        <div
          class="flex items-center space-x-1.5 justify-end md:justify-between rtl:space-x-reverse dark:text-[#d0d2d6] sm:flex-1 ltr:sm:ml-0 sm:rtl:mr-0 lg:space-x-2"
        >
          <NuxtLink
            class="btn btn-danger"
            to="./"
          >
            Salir de POS
          </NuxtLink>
          <h2 class="hidden md:block font-bold text-lg">
            <!-- {{ $t('description') }} -->
            <span style="font-weight: bold">Bienvenido a <span style="color: rgb(55, 161, 231)">Efici</span> - Gestionamos tu Comercio con
              <span style="color: rgb(55, 161, 231)">Efici</span>encia</span>
          </h2>

          <nav class="flex items-center space-x-1.5">
            <div>
              <a
                v-show="store.theme === 'light'"
                href="javascript:;"
                class="flex items-center rounded-full bg-white-light/40 p-2 hover:bg-white-light/90 hover:text-primary dark:bg-dark/40 dark:hover:bg-dark/60"
                @click="store.toggleTheme('dark')"
              >
                <Icon name="hugeicons:sun-02" />
              </a>
              <a
                v-show="store.theme === 'dark'"
                href="javascript:;"
                class="flex items-center rounded-full bg-white-light/40 p-2 hover:bg-white-light/90 hover:text-primary dark:bg-dark/40 dark:hover:bg-dark/60"
                @click="store.toggleTheme('system')"
              >
                <Icon name="hugeicons:moon-02" />
              </a>
              <a
                v-show="store.theme === 'system'"
                href="javascript:;"
                class="flex items-center rounded-full bg-white-light/40 p-2 hover:bg-white-light/90 hover:text-primary dark:bg-dark/40 dark:hover:bg-dark/60"
                @click="store.toggleTheme('light')"
              >
                <Icon name="hugeicons:laptop" />
              </a>
            </div>

            <LocaleSelector />
            <div class="dropdown shrink-0">
              <client-only>
                <Popper
                  :placement="store.rtlClass === 'rtl' ? 'bottom-end' : 'bottom-start'"
                  offset-distance="8"
                  class="!block"
                >
                  <button
                    type="button"
                    class="group relative block"
                  >
                    <img
                      class="h-9 w-9 rounded-full object-cover saturate-50 group-hover:saturate-100"
                      :src="user.imageUser !== '' && user.imageUser ? user.imageUser : '/assets/img/domiplace-not-found.png'"
                      alt=""
                    >
                  </button>
                  <template #content="{ close }">
                    <ul class="w-[230px] !py-0 font-semibold text-dark dark:text-white-dark dark:text-white-light/90">
                      <li>
                        <div class="flex items-center px-4 py-4">
                          <div class="flex-none">
                            <img
                              class="h-10 w-10 rounded-md object-cover"
                              :src="user.imageUser !== '' && user.imageUser ? user.imageUser : '/assets/img/domiplace-not-found.png'"
                              alt=""
                            >
                          </div>
                          <div class="truncate ltr:pl-4 rtl:pr-4">
                            <h4 class="text-base">
                              {{ user.firstName
                              }}<span class="rounded bg-success-light px-1 text-xs text-white ltr:ml-2 rtl:ml-2">{{
                                user.id_roleAdmin === 1 ? 'Admin' : 'User'
                              }}</span>
                            </h4>
                            <a
                              class="text-black/60 hover:text-primary dark:text-dark-light/60 dark:hover:text-white"
                              href="javascript:;"
                            >{{ user.email }}</a>
                          </div>
                        </div>
                      </li>
                      <li>
                        <NuxtLink
                          to="#"
                          class="dark:hover:text-white"
                          @click="close()"
                        >
                          <Icon name="hugeicons:user" class="shrink-0 ltr:mr-2 rtl:ml-2" />
                          {{ $t('Profile') }}
                        </NuxtLink>
                      </li>
                      <li class="border-t border-white-light dark:border-white-light/10">
                        <NuxtLink
                          to="#"
                          class="!py-3 text-danger"
                          @click="close()"
                        >
                          <icon-logout class="h-4.5 w-4.5 shrink-0 rotate-90 ltr:mr-2 rtl:ml-2" />

                          {{ $t('SignOut') }}
                        </NuxtLink>
                      </li>
                    </ul>
                  </template>
                </Popper>
              </client-only>
            </div>
          </nav>
        </div>
      </div>
    </div>
  </header>
</template>
